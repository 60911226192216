import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Table from "~/components/table-lesson-2";
import Footnote from "~/components/footnote/footnote";

const headerText = [
  "Type of website",
  "Accuracy rating"
];

const data = [
  ["Government", "high"],
  ["National organization", "high"],
  ["Educational", "medium"],
  ["News site", "medium"],
  ["Personal blog", "low"],
  ["Product review", "low"]
];

const Page = () => {
  return (
      <LessonLayout
        current_step={2}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 2</H2>

          <p>In a recent study, investigators searched the web to find answers to health questions. They wanted to find out if health information on websites is accurate.* They looked at 1,300 websites.</p>
          <p>Click on each type of website to learn about the main reason for the accuracy rating.</p>

          <Table data={data} headerText={headerText} color="hs"/>

          <p>Other studies have found similar issues with the accuracy of information on the internet. Health professionals are concerned because many people do not realize that much of the health information on the internet is inaccurate or incomplete as they use websites when making health decisions.</p>
          <Footnote
            author="Chung, M., Oden, R. P., Joyner, B. L., Sims, A., and Moon, R. Y. (2012). Safe Infant Sleep Recommendations on the Internet: Let’s Google It."
            footnoteNumber="*"
            href="https://doi.org/10.1016/j.jpeds.2012.06.004"
            source="(6):1080-4. DOI:"
            title="Journal of Pediatrics, 161"
          />
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
